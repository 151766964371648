import "./css/menuBar.css";
import "./css/queries.css";
import { IoMenu } from "react-icons/io5";
import { BsPerson } from "react-icons/bs";

function MenuBar() {
  return (
    <div className="menu-container">
      <div className="menu-bar-outer-container">
        <div className="menu-bar-container">
          <div className="menu-bar-logo">
            <IoMenu className="menu-icon" />
            Menu
          </div>
          <div className="comp-name">
            <div className="comp-name-item">C E N T U R Y W A V E S</div>
            {/* <div>ABOUT US</div>
            <div>SERVCES</div>
            <div>INDUSTRIES</div>
            <div>LATEST NEWS</div>
            <div>CONTACT US</div> */}
          </div>
          <div className="menu-login">
            {/* <div>Help</div> */}

            {/* <div className="menu-account"> */}
            {/* <div>Login</div> */}
            <BsPerson className="login-icon" />
            {/* <RiArrowDropDownLine className="menu-bar-search" /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuBar;
