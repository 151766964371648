// import Demo from "./pages/Demo";
import HomePage from "./pages/HomePage";
// import Product from "./demo/Product";

// import Home from "./demo1/Home";

function App() {
  return (
    <div>
      <HomePage />
      {/* <Demo /> */}
    </div>
  );
}

export default App;
