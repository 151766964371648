import "./css/intro.css";
import "./css/queries.css";

function Intro() {
  return (
    <>
      {/* <div className="intro-title-container">Make Your App Run faster!</div> */}
      <div>
        <div className="intro-title-container">
          Under maintenance, please try after some time.
        </div>
        <div>
          <div className="intro-text-container">
            {/* At Centurywaves, we listen to your business needs and provide the best */}
            {/* software solutions with cutting edge technologies. */}
            {/* performance & Security on apps, tools & websites, whereby 
                increasing revenue for businesses.  */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Intro;
