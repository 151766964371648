import "./css/hero.css";
// import ourServiceHero from "../images/paid-4.jpg";
import ourServiceHero from "../images/paid-0.jpg";


function Hero() {
  return (

    <div className="hero-head-container">
      <img
        className="hero-image"
        alt="a lady with her computer wearing white dress and a eye glasses"
        src={ourServiceHero}
      />
    </div>
  );
}

export default Hero;
